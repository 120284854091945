<template>
  <div as="template" :show="show">
    <div as="div" class="relative z-10">
      <div
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </div>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <div
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              v-loading="loading"
              class="relative transform overflow-hidden rounded-lg bg-white px-4 py-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl"
            >
              <div class="flex justify-end -mt-3 mb-2 cursor-pointer" @click="closeModal()">
                <!-- Heroicon name: solid/x-circle -->
                <svg
                  class="h-6 w-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div>
                <div class="mt-3 text-left sm:mt-5">
                  <section class="mt-2">
                    <p class="text-sm text-gray-700">
                      The
                      <a class="cursor-pointer" @click="openDocument(fileObj)"
                        >Elevation Certificate</a
                      >
                      uploaded to this policy was unable to be matched to the policy address or EC
                      Value entered. Please review the document and take appropriate action.
                    </p>
                  </section>
                  <section class="mt-5">
                    <p>
                      <span class="font-bold">Invalid Reason(s): </span>{{ data.invalidReason }}
                    </p>
                    <br />
                    <p><span class="font-bold">Scanned Address: </span>{{ data.scannedAddress }}</p>
                    <p><span class="font-bold">Policy Address: </span>{{ computedAddress }}</p>
                    <br />
                    <p>
                      <span class="font-bold">Elevation Based On: </span
                      >{{ data.elevationBasedOn ?? "-" }}
                    </p>
                    <br />
                    <p><span class="font-bold">Scanned c2A: </span>{{ data.c2A ?? "-" }}</p>
                    <p><span class="font-bold">Scanned c2E: </span>{{ data.c2E ?? "-" }}</p>
                    <p>
                      <span class="font-bold">Policy EC Value: </span
                      >{{ $store.state.policy.transactionSummary.ecElevation ?? "-" }}
                    </p>
                    <br />
                    <p><span class="font-bold">Form Code: </span>{{ data.forCode ?? "-" }}</p>
                  </section>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <p class="text-sm text-gray-700 mb-1">
                  If the document matches the policy, click here to clear the error.
                </p>
                <div class="flex justify-end">
                  <button
                    type="button"
                    class="inline-flex w-1/2 justify-center rounded-md bg-deepsea-full px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-deepsea-full/75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-deepsea-full"
                    @click="verify"
                  >
                    Verified as Correct
                  </button>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <p class="text-sm text-gray-700 mb-1">
                  If the document is incorrect, prepare a mandatory endorsement to correct the
                  policy, then mark the error as corrected.
                </p>
                <div class="flex justify-end">
                  <button
                    type="button"
                    class="inline-flex w-1/2 justify-center rounded-md px-3 py-2 text-sm font-semibold transition duration-200 ease-in-out border border-gray-300 text-red-600 shadow-sm hover:bg-red-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-300 hover:border-red-300"
                    @click="request"
                  >
                    Correction Endorsement Requested
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authAxios } from "@/store/modules/auth";
import { Constants } from "@/config";
import Swal from "sweetalert2";
export default {
  props: {
    data: Object,
    show: Boolean,
    fileObj: Object
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    confirmationData() {
      return this.$store?.state?.policy?.confirmationData;
    },
    computedAddress() {
      let address = `${this.confirmationData?.address1} ${this.confirmationData?.city}, ${this.confirmationData?.state} ${this.confirmationData?.zipCode}`;
      if (this.confirmationData?.address2)
        address = `${this.address1} ${this.confirmationData?.address2} ${this.confirmationData?.city}, ${this.confirmationData?.state} ${this.confirmationData?.zipCode}`;
      return address ?? "Address not found.";
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    openDocument(item) {
      if (!this.canOpen(item)) return;
      const path = Constants.GET_PDF_URL + "?link=" + item.link;
      window.open(path, "_blank");
    },
    canOpen(item) {
      const { link } = item;

      return !!link;
    },
    async verify() {
      try {
        this.loading = true;
        await authAxios.put(
          `${Constants.VERIFIED_EC_DOC.replace("{{verId}}", this.data.policyDocVerId)}/6`
        );
        Swal.fire({
          text: "Changes were submitted!",
          icon: "success",
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        });
      } catch (e) {
        Swal.fire({
          title: "Error!",
          text: "There was an error posting these changes",
          icon: "error",
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        });
        throw new Error(e);
      } finally {
        this.$emit("close");

        this.loading = false;
      }
    },
    async request() {
      try {
        this.loading = true;
        await authAxios.put(
          `${Constants.VERIFIED_EC_DOC.replace("{{verId}}", this.data.policyDocVerId)}/8`
        );
        Swal.fire({
          text: "Changes were submitted!",
          icon: "success",
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        });
      } catch (e) {
        Swal.fire({
          title: "Error!",
          text: "There was an error posting these changes",
          icon: "error",
          toast: true,
          position: "top",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        });
        throw new Error(e);
      } finally {
        this.$emit("close");
        this.loading = false;
      }
    }
  }
};
</script>
