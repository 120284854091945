import store from "@/store";

export const routeToV2 = (param) => {
  const jwtToken = store.state.auth.token;
  const jwtTokenExpire = new Date(new Date().getTime() + 30 * 60000).getTime();
  const jwtTokenPlusFive = btoa(jwtToken + ".TD" + jwtTokenExpire);
  const url = `${process.env.VUE_APP_INTRANET_V2_URL}/auth`;
  const commissionsURL = new URL(url + "/" + jwtTokenPlusFive);
  commissionsURL.searchParams.append("redirect_uri", param);
  const a = document.createElement("a");
  a.href = commissionsURL;
  a.click();
};
