<template>
  <div>
    <div class="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-2 items-center">
      <div>
        <button
          v-if="canSeeCheckOption"
          class="text-white font-bold text-center px-3.5 py-2.5 rounded-md min-w-[80px] text-sm"
          :class="{
            'bg-deepsea-full/10 text-deepsea-full hover:bg-deepsea-full/20': !$isEarthquake,
            'bg-orange-100 text-orange-900 hover:bg-orange-200': $isEarthquake
          }"
          data-test="applyPaymentButton"
          @click.prevent="showApplyPaymentDialog()"
        >
          Apply Payment
        </button>
      </div>
    </div>
    <div v-if="rowData.length > 0">
      <div :loading="loading" class="max-h-96 max-w-6xl overflow-x-scroll">
        <div v-if="rowData.length" class="-mx-4 sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="sticky top-0 bg-white">
                <tr>
                  <th
                    v-if="$internal"
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    data-test="paymentGrid.transferTitle"
                  >
                    <span>Transfer</span>
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" data-test="paymentGrid.receiptTitle">
                    <span>Receipt</span>
                  </th>
                  <th
                    v-if="checkNoExists"
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    data-test="paymentGrid.checkNoTitle"
                  >
                    Check Number
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" data-test="paymentGrid.paymentIdTitle">
                    Payment ID
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" data-test="paymentGrid.createdTitle">
                    Created
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" data-test="paymentGrid.typeTitle">
                    Type
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" data-test="paymentGrid.statusTitle">
                    Status
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" data-test="paymentGrid.amountTitle">
                    Amount
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" data-test="paymentGrid.descriptionTitle">
                    Description
                  </th>
                </tr>
              </thead>

              <tbody class="divide-y divide-gray-200">
                <tr v-for="(payment, index) in rowData" :key="index">
                  <td
                    v-if="canTransferPayment(payment)"
                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium cursor-pointer"
                    data-test="paymentGrid.transfer"
                  >
                    <button
                      class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                      @click="transfer(payment)"
                    >
                      Transfer
                    </button>
                  </td>
                  <td
                    v-else-if="$internal"
                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium cursor-pointer"
                  ></td>
                  <td
                    v-if="canShowReceipt(payment)"
                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium cursor-pointer"
                    data-test="paymentGrid.receipt"
                  >
                    <button
                      class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                      @click="onReceiptClick(payment)"
                    >
                      Receipt
                    </button>
                  </td>
                  <td
                    v-else
                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium cursor-pointer"
                  ></td>
                  <td
                    v-if="checkNoExists"
                    class="whitespace-nowrap py-4 pl-4 pr-3  text-sm font-medium text-gray-900"
                    data-test="paymentGrid.checkNo"
                  >
                    {{ payment.checkNo }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
                    data-test="paymentGrid.paymentId"
                  >
                    {{ payment.paymentId }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
                    data-test="paymentGrid.createdAt"
                  >
                    {{ payment.createdAt | shortDateSimpleWithTime }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
                    data-test="paymentGrid.type"
                  >
                    {{ payment.paymentTypeDescription }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
                    data-test="paymentGrid.status"
                  >
                    {{ payment.paymentStatus }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
                    data-test="paymentGrid.amount"
                  >
                    {{ payment.amount | currency }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
                    data-test="paymentGrid.description"
                  >
                    {{ payment.description }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-else key="emptyState" class="flex items-center justify-center w-full">
      <div class="text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            vector-effect="non-scaling-stroke"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
          />
        </svg>
        <h3 class="mt-2 text-sm font-medium text-gray-900" data-test="notificationsHeader">
          No payments have been made for this policy
        </h3>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogShowApplyPayment"
      style="height: 850px;"
      :append-to-body="true"
      title="Apply Check"
      width="30%"
      custom-class="dialog-class"
    >
      <el-form v-loading="loading">
        <el-form-item label="Check Number" :required="true">
          <ValidationProvider
            v-slot="{ errors }"
            :rules="'min_value:1|required|max:15'"
            name="check number"
          >
            <el-input
              v-model="payment.checkNo"
              data-test="checkNumber"
              placeholder="Please enter the check number"
              type="number"
              @wheel.native.prevent=""
              @keypress.native="validateKeypress"
            />
            <p
              v-show="errors && errors.length"
              data-test="checkNumberError"
              class="text-xs text-red-500 font-bold select-none animated fadeIn"
            >
              {{ errors[0] }}
            </p>
          </ValidationProvider>
        </el-form-item>
        <el-form-item label="Remitter Name" :required="true">
          <ValidationProvider v-slot="{ errors }" :rules="'required'" name="Remitter Name">
            <el-input
              v-model="payment.payeeName"
              data-test="remitterName"
              placeholder="Please enter the remitter name"
              type="text"
            />
            <p
              v-show="errors && errors.length"
              data-test="remitterNameError"
              class="text-xs text-red-500 font-bold select-none animated fadeIn"
            >
              Remitter name is required.
            </p>
          </ValidationProvider>
        </el-form-item>
        <el-form-item label="Memo" :required="true">
          <ValidationProvider v-slot="{ errors }" :rules="'required|max:100'" name="Memo">
            <el-input
              v-model="payment.memo"
              placeholder="Please enter the memo"
              data-test="memo"
              type="text"
            />
            <p
              v-show="errors && errors.length"
              data-test="remitterNameError"
              class="text-xs text-red-500 font-bold select-none animated fadeIn"
            >
              {{ errors[0] }}
            </p>
          </ValidationProvider>
        </el-form-item>
        <div class="flex amount-date-row">
          <div class="flex-1 mr-2">
            <el-form-item label="Payment Amount" :required="true">
              <ValidationProvider
                v-slot="{ errors }"
                :rules="'min_value:1|required'"
                name="Check Number"
              >
                <el-input
                  v-model="payment.amount"
                  data-test="paymentAmount"
                  placeholder="Please enter the amount"
                  type="number"
                  @wheel.native.prevent=""
                />
                <p
                  v-show="errors && errors.length"
                  data-test="paymentAmountError"
                  class="text-xs text-red-500 font-bold select-none animated fadeIn"
                >
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </el-form-item>
          </div>
          <div class="flex flex-col">
            <el-form-item label="Check Deposit Date" :required="true">
              <ValidationProvider v-slot="{ errors }" :rules="'required'" name="Deposit Date">
                <el-date-picker
                  v-model="payment.createdAt"
                  data-test="depositDate"
                  placeholder="Please enter the date"
                  style="width:100%"
                />
                <p
                  v-show="errors && errors.length"
                  data-test="dateError"
                  class="text-xs text-red-500 font-bold select-none animated fadeIn"
                >
                  Please select a date.
                </p>
              </ValidationProvider>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelApplyPaymentDialog()">Cancel</el-button>
        <el-button
          v-loading="loading"
          data-test="applyPaymentCheckButton"
          :disabled="disableApplyPayment"
          class="!bg-deepsea-full hover:!bg-deepsea-full/75 !text-white"
          :class="{
            'bg-orange-100 text-orange-900 hover:bg-orange-200': $isEarthquake
          }"
          @click="applyPayment()"
        >
          Apply
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { DateFiltersMixin, NumberFiltersMixin } from "../../policy-wizard/utils/mixins/formatting";
import { mapActions } from "vuex";
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider
  },
  mixins: [DateFiltersMixin, NumberFiltersMixin],
  inheritAttrs: false,
  model: {
    prop: "rowData"
  },
  props: {
    rowData: {
      type: Array
    },
    policyNo: {
      type: String
    },
    defaultPaymentMethod: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      payment: {
        checkNo: "",
        payeeName: "",
        memo: "",
        amount: null,
        createdAt: null
      },
      dialogShowApplyPayment: false,
      showUpdatePayment: false,
      showRemovePayment: false
    };
  },
  computed: {
    checkNoExists() {
      let bool = false;
      this.rowData.map((x) => {
        if (x.checkNo) {
          bool = true;
        }
      });
      return bool;
    },
    isEarthquake() {
      return (
        this.$store.getters["policy/productNo"] === 4 ||
        this.$store.getters["policy/productNo"] === 5
      );
    },
    canSeeCheckOption() {
      return (
        this.$store?.state?.auth?.data?.AgentId === "FL0008" ||
        this.$store?.state?.auth?.data?.AgentId === "FL53596" ||
        this.$store?.state?.auth?.data?.AgentId === "FL50267" ||
        this.$store?.state?.auth?.data?.AgentId === "FL53597"
      );
    },
    allData() {
      return this.$store.getters["policy/allData"];
    },
    disableApplyPayment() {
      if (this.loading) return true;
      return !(
        this.payment.checkNo &&
        this.payment.payeeName &&
        this.payment.amount &&
        this.payment.createdAt &&
        this.payment.checkNo !== "0" &&
        this.payment.memo
      );
    },
    showPaymentChangeButton() {
      return Boolean(this.defaultPaymentMethod?.default);
    },
    paymentInterval() {
      return this.$store?.state?.policy?.confirmationData?.paymentInterval;
    },
    paymentMethod() {
      return {
        paymentType: this.defaultPaymentMethod?.stripePaymentMethod?.paymentType,
        brand: this.defaultPaymentMethod?.stripePaymentMethod?.cardBrand,
        lastFour: this.defaultPaymentMethod?.stripePaymentMethod?.lastFourDigits,
        expiration: this.defaultPaymentMethod?.stripePaymentMethod?.expiration
      };
    }
  },
  methods: {
    validateKeypress($event) {
      if (
        ($event.charCode !== 8 && $event.charCode === 0) ||
        ($event.charCode >= 48 && $event.charCode <= 57)
      ) {
        return true;
      } else {
        $event.preventDefault();
      }
    },
    onReceiptClick(payment) {
      this.$router.push({
        path: `${this.$route.fullPath}/receipt/${payment.policyPaymentId}`,
        params: {
          createdAt: payment.createdAt
        }
      });
    },
    transfer(payment) {
      this.$root.$emit("openTransferPaymentModal", payment);
    },
    showApplyPaymentDialog() {
      this.payment = {
        checkNo: "",
        payeeName: "",
        memo: "",
        amount: null,
        createdAt: null
      };
      this.dialogShowApplyPayment = true;
    },
    ...mapActions("payment", ["postCheckPayment"]),
    removePaymentMethod() {
      this.showRemovePayment = true;
    },
    closeRemovePaymentMethod() {
      this.showRemovePayment = false;
    },
    onRemovePaymentMethod() {
      this.showRemovePayment = false;
      this.$emit("refreshPaymentMethod");
    },
    closeUpdateModal() {
      this.showUpdatePayment = false;
    },
    showPaymentError() {
      this.$notify.error({
        title: "Error",
        message: "There was an error applying this check."
      });
    },
    showCheckSuccess() {
      this.$notify({
        title: "Success",
        message: "Check was successfully applied!",
        type: "success"
      });
    },

    cancelApplyPaymentDialog() {
      this.dialogShowApplyPayment = false;
    },
    canTransferPayment(payment) {
      const { invoices } = this.allData;
      const { isAppliedToPolicy, amount, paymentStatus } = payment;

      const paymentArr = this.rowData.filter((x) => x.paymentId === payment.paymentId);
      let total = 0;
      paymentArr.map((x) => (total = x.amount + total));
      return (
        this.$internal &&
        !this.$readonly &&
        !(
          total === 0 ||
          paymentStatus === "failed" ||
          amount <= 0 ||
          paymentStatus === "cancelled" ||
          isAppliedToPolicy === false ||
          (invoices.due === 0 && invoices.paid === 0) ||
          payment.paymentTypeId === 12
        )
      );
    },
    async applyPayment() {
      try {
        this.loading = true;
        await this.postCheckPayment({ policyNo: this.policyNo, checkBody: this.payment });
        await this.$store.dispatch("policy/getPolicyDetails", this.policyNo);
        this.dialogShowApplyPayment = false;
        this.showCheckSuccess();
      } catch (e) {
        this.showPaymentError();
      } finally {
        this.loading = false;
      }
    },
    determineTitle(payment) {
      const { isAppliedToPolicy, amount, paymentStatus } = payment;
      if (paymentStatus === "failed") {
        return "Payment failed";
      } else if (amount < 0) {
        return "Payment has already been transferred";
      } else if (paymentStatus === "cancelled") {
        return "Payment has been cancelled";
      } else if (!isAppliedToPolicy) {
        return "Payment has not been applied";
      } else if (!this.isInternal) {
        return "Internal use only";
      } else {
        return "Transfer payment";
      }
    },
    canShowReceipt(payment) {
      return payment.paymentTypeId !== 12;
    }
  }
};
</script>
<style lang="scss" scoped>
.amount-date-row {
  @media screen and (max-width: 1440px) {
    display: flex;
    flex-direction: column;
  }
}

::v-deep .dialog-class {
  @media screen and (max-width: 1440px) {
    height: 850px;
  }

  min-width: 490px;
}

::v-deep .update-dialog {
  height: 100%;
  width: 100%;

  @media screen and (min-width: 768px) {
    max-width: 500px;
    height: 600px;
    margin: unset;
    margin-left: auto;
    margin-right: auto;
  }

  // need to set !important up to 767
  // in order to address element-ui margin style
  // directly on the element.
  @media screen and (max-width: 767px) {
    margin: 0 !important;
  }
}
</style>
