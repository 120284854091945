<template>
    <div class="fixed inset-0 z-[2009] flex items-center justify-center px-4">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75" @click="$emit('close')"></div>

        <div class="bg-white max-w-lg h-100 w-full relative z-10 p-6 rounded-md">
            <svg xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 absolute top-0 right-0 text-gray-400 hover:text-gray-900 mt-4 mr-4 cursor-pointer"
                viewBox="0 0 20 20" fill="currentColor" @click="$emit('close')">
                <path fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd" />
            </svg>
            <div class="flex flex-col items-center justify-around gap-2">
                <p >
                    Are you sure you wish to end this moratorium?
                </p>
                <p >Enter the stop date below:</p>
                <input
                    v-model="stopDate"
                    type="datetime-local"
                    :picker-options="pickerOptions"
                />
            </div>

            <div slot="footer" class="flex justify-end space-x-2 mt-4">
                <button
                class="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                @click.prevent="$emit('close')"
                >
                    Cancel
                </button>

                <button
                  type="primary"
                  class="inline-flex items-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 min-w-[69px]"
                  :class="{ 'cursor-not-allowed': loading }"
                  :disabled="loading"
                  @click="stopMoratorium"
                >
                    <span v-if="!loading">
                        Confirm
                    </span>
                    <svg
                        v-else
                        class="animate-spin h-5 w-5 text-white mx-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                        ></circle>
                        <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>
<script>

import { mapActions } from 'vuex';

export default {
    props: {
        moratorium: Object,
        loadData: Function
    },
    data() {
        return {
            stopDate: '',
            loading: false,
            pickerOptions: {
                disabledDate(date) {
                    const yesterday = new Date();
                    const tomorrow = new Date();
                    yesterday.setDate(yesterday.getDate() - 1);
                    tomorrow.setDate(tomorrow.getDate());
                    tomorrow.setHours(23, 59, 59);
                    return date <= yesterday || date >= tomorrow;
                }
            }

        };
    },
    methods: {
        ...mapActions("moratoriums", ["postMoratorium"]),
        async stopMoratorium() {
            try {
                this.loading = true;
                const stopDate = new Date(this.stopDate);
                this.moratorium.stopDate = stopDate
                    .toISOString()
                    .slice(0, stopDate.toISOString().indexOf("."));
                await this.postMoratorium(this.moratorium);
                this.loadData();
                this.$emit("close");
            } catch (e) {
                throw new Error(e);
            } finally {
                this.showStopModal = false;
                this.stopDate = "";
                this.loading = false;
            }
        }
    }
};
</script>
