import Vue from "vue";
import Router from "vue-router";
import { Constants } from "../config";
import { routeToV2 } from "@/utils/routeToV2";

/**
 * External Content
 * ! Merge with main route config
 */

// import AppLayout from '@/layouts/AppLayout.vue'
import Error404 from "@/views/Error404.vue";
import ErrorPageNotFound from "@/views/PageNotFound.vue";
import Unauthorized from "@/components/Unauthorized.vue";
import { hierarchy } from "d3";

import profile from "./profile";
import store from "@/store";
import policyHolder from "./policyholder";
import agent from "./agent";
import admin from "./admin";
import billing from "@/billing/routes";

// const CmsLayout = () => import(/* webpackChunkName: "newlayout" */ '@/views/AdminSection.vue')
import CmsLayout from "@/views/AdminSection.vue";
import EmbedLayout from "@/views/EmbedLayout.vue";

// eslint-disable-next-line no-unused-vars
export function extractToken(base64DateStr) {
  if (!base64DateStr) return;
  const tkConv = window.atob(base64DateStr);
  // inspect the date:
  const tkDateIndex = tkConv.indexOf(".TD");
  const tkDate = tkConv.substr(tkDateIndex).replace(".TD", "");
  const tokenDate = new Date(Number(tkDate));
  if (tokenDate < new Date()) {
    // token expired
    return false;
  }

  return tkConv.substr(0, tkDateIndex);
}

/**
 * @type {import('vue-router').NavigationGuard}
 */
export const checkAuth = async (to, from, next) => {
  if (to.name === "login") {
    // debugger
    next();
    return;
  }
  if (to.fullPath.includes("auth")) {
    next();
    return;
  }

  const stateToken = store.state.auth.token || store.state.auth.data?.Token;
  const policyHolderToken = store.state.auth.validateReturn?.jwt;

  /**
   * If there is a token in the url
   * check for validity
   */
  const urlToken = to.params?.token || to.query?.token;
  if (urlToken) {
    // debugger
    const validToken = extractToken(urlToken);
    const shortPath = to.fullPath.replace("/" + urlToken, "");
    if (validToken) {
      await store.dispatch("auth/loginWithToken", validToken);
      next(shortPath);
    } else {
      next({
        name: "login",
        query: {
          returnurl: shortPath
        }
      });
    }

    return;
  }

  /**
   * Policy Holder
   */
  if (to.fullPath.includes("policyholder/")) {
    // Apply auth headers if token is found
    if (policyHolderToken) {
      store.commit("auth/SET_TOKEN", policyHolderToken);
      next();
    }

    if (to.meta.auth !== "undefined" && to.meta.auth !== false && !policyHolderToken) {
      next({
        name: "generatePolicyEmail",
        params: to.params
      });
    } else {
      next();
    }
    return;
  }

  /** Agent */
  if (to.fullPath.includes("agent")) {
    next();
    return;
  }

  /**
   * If no token
   */
  if (!stateToken) {
    next({
      name: "login",
      query: {
        returnurl: to.fullPath
      }
    });

    return;
  }

  next();
};

/**
 * Main routes of the application.
 * Combines all categories into one RouteConfig
 * @type {import('vue-router').RouteConfig[]}
 */
export const routes = [
  /**
   * TODO: Remove Redirect
   * Until we implement a mock setup,
   * i am redirecting to the login layout
   */
  {
    path: "/",
    name: "root",
    redirect: process.env.NODE_ENV === "production" ? "/splash" : "/policies"
  },

  /**
   *
   * .##.....##....###....####.##....##
   * .###...###...##.##....##..###...##
   * .####.####..##...##...##..####..##
   * .##.###.##.##.....##..##..##.##.##
   * .##.....##.#########..##..##..####
   * .##.....##.##.....##..##..##...###
   * .##.....##.##.....##.####.##....##
   *
   * Start of main application
   * After user has logged in
   */
  {
    meta: {
      title: "Main",
      description: "Main entry point",
      icon: "fa-tv"
      // theme:       'light'
    },
    path: "/app",
    component: CmsLayout,
    // eslint-disable-next-line no-unused-vars
    beforeEnter(_x1, _x2, next) {
      if (!store.getters["auth/roles"].includes("superuser")) {
        return next("/unauthorized");
      }
      next();
    },
    children: [
      {
        meta: {
          title: "Policies",
          description: "Manage all of your policies in one place.",
          icon: "fa-home"
        },
        path: "/policies",
        component: () =>
          import(/* webpackChunkName: "Policies" */ "@/components/routes/Policies.vue")
      },
      {
        name: "admin-policy-detail",
        meta: {
          title: "Policy Detail",
          description: "Manage all of your policies in one place.",
          icon: "fa-home",
          theme() {
            const productNo = store.getters["policy/productNo"];
            if (Constants.JUMPSTART_ENABLED && (productNo === 4 || productNo === 5)) {
              return "earthquake";
            }
            return productNo === 1 ? "neptune" : "commercial";
          },
          isHiddenFromSideNav: true
        },
        path: "/policies/:policyNo?",
        props: true,

        component: () =>
          import(/* webpackChunkName: "PolicyDetail" */ "@/components/routes/PolicyDetail.vue")
      },

      {
        meta: {
          title: "Commissions",
          description: "Manage Commissions"
        },
        beforeEnter(_x1, _x2, next) {
          if (!store.getters["auth/roles"].includes("financeadmin")) {
            return next("/unauthorized");
          }
          routeToV2("/commissions");
        },
        path: "/commissions",
        component: () => import("../components/routes/commissions/Commissions.vue")
      },
      {
        meta: {
          title: "Commission Overrides",
          description: "Manage Commission Overrides"
        },
        beforeEnter(_x1, _x2, next) {
          if (!store.state.auth.data.Roles.includes("commissionAdmin")) {
            return next("/unauthorized");
          }
          routeToV2("/overrides");
        },
        path: "/overrides",
        component: () =>
          import("../components/routes/commissions/overrides/CommissionOverrides.vue")
      },
      {
        meta: {
          title: "Commission Override Detail",
          description: "Manage Commission Overrides"
        },
        beforeEnter(_x1, _x2, next) {
          if (!store.getters["auth/roles"].includes("financeadmin")) {
            return next("/unauthorized");
          }
          next();
        },
        path: "/overrides/:agencyNo",
        component: () =>
          import("../components/routes/commissions/overrides/CommissionOverrideDetail.vue")
      },
      {
        meta: {
          title: "Commissions",
          description: "Manage Commissions",
          isHiddenFromSideNav: true
        },
        beforeEnter(_x1, _x2, next) {
          if (!store.getters["auth/roles"].includes("financeadmin")) {
            return next("/unauthorized");
          }
          next();
        },
        path: "/commissions/:statementId",
        component: () => import("../components/routes/commissions/CommissionDetail.vue")
      },
      // {
      //   meta: {
      //     title: "Commissions",
      //     description: "Manage Commissions",
      //     isHiddenFromSideNav: true
      //   },
      //   beforeEnter(_x1, _x2, next) {
      //     if (store.getters["auth/agentNo"] !== "FL0008") {
      //       return next("/unauthorized");
      //     }
      //     next();
      //   },
      //   path: "/auth/:token?",
      //   component: () => import("../components/routes/commissions/Commissions.vue")
      // },
      {
        meta: {
          title: "Moratoriums",
          description: "Manage Moratoriums"
        },
        beforeEnter(_x1, _x2, next) {
          if (!store.getters["auth/roles"].includes("superuser")) {
            return next("/unauthorized");
          }
          next();
        },
        path: "/moratoriums",
        component: () => import("../components/routes/moratoriums/Moratoriums.vue")
      },
      {
        meta: {
          title: "Commission Detail",
          isHiddenFromSideNav: true
        },
        path: "/commissions/:statementId?/:statementMonth?/:statementYear?",
        props: true,
        beforeEnter(_x1, _x2, next) {
          if (!store.getters["auth/roles"].includes("financeadmin")) {
            return next("/unauthorized");
          }
          next();
        },
        component: () =>
          import(
            /* webpackChunkName: "PolicyDetail" */ "@/components/routes/commissions/CommissionDetail.vue"
          )
      },
      {
        meta: {
          title: "Receipts",
          isHiddenFromSideNav: true
        },
        path: "/policies/:policyNo/receipt/:policyPaymentId",
        theme() {
          const productNo = store.getters["policy/productNo"];
          if (Constants.JUMPSTART_ENABLED && (productNo === 4 || productNo === 5)) {
            return "earthquake";
          }
          return productNo === 1 ? "neptune" : "commercial";
        },
        component: () =>
          import(/* webpackChunkName: "Receipts" */ "@/components/receipts/Receipts.vue")
      },
      {
        meta: {
          title: "Notifications",
          isHiddenFromSideNav: true
        },
        path: "/policies/:policyNo/notifications",
        props: true,

        component: () =>
          import(
            /* webpackChunkName: "Notification" */ "@/components/notifications/Notifications.vue"
          )
      },
      {
        meta: {
          title: "Email Notification",
          isHiddenFromSideNav: true
        },
        path: "/policies/:policyNo/notifications/emailNotifications/:id/:path/:version",
        props: true,

        component: () =>
          import(
            /* webpackChunkName: "Notification" */ "@/components/notifications/NotificationTemplate.vue"
          )
      },
      {
        meta: {
          title: "Email Notification",
          isHiddenFromSideNav: true
        },
        path: "/policies/:policyNo/notifications/emailNotifications/:id/:path",
        props: true,

        component: () =>
          import(
            /* webpackChunkName: "Notification" */ "@/components/notifications/NotificationTemplate.vue"
          )
      },

      {
        path: "/policies/:policyNo/claims/:id",
        component: () => import("@/components/policy/claims/ClaimDetail.vue"),
        props: true,
        name: "claimDetailCompleteInternal",
        meta: {
          title: "Claim View",
          isHiddenFromSideNav: true,
          theme() {
            const productNo = store.getters["policy/productNo"];
            return productNo === 1 ? "neptune" : "commercial";
          }
        }
      }
    ]
  },
  {
    path: "/auth/agent",
    name: "agentPolicy",
    async beforeEnter(to, from, next) {
      try {
        const newToken = to.query?.token;
        const actual = extractToken(newToken);

        await store.dispatch("auth/loginWithToken", actual);

        return next(to.query?.redirectTo);
      } catch {
        window.location = Constants.BASE_TRITON_UI_URL;
      }
    }
  },

  {
    path: "/policy/embed",
    component: EmbedLayout,
    meta: {
      title: "Policy Viewer",
      isHiddenFromSideNav: true,
      theme() {
        const productNo = store.getters["policy/productNo"];
        if (Constants.JUMPSTART_ENABLED && (productNo === 4 || productNo === 5)) {
          return "earthquake";
        }
        return productNo === 1 ? "neptune" : "commercial";
      }
    },
    children: [
      {
        meta: {
          title: "Notifications",
          isHiddenFromSideNav: true
        },
        path: ":policyNo/notifications",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "Notification" */ "@/components/notifications/Notifications.vue"
          )
      },
      {
        meta: {
          title: "Receipts",
          isHiddenFromSideNav: true
        },
        path: ":policyNo/receipt/:policyPaymentId",
        props: true,
        component: () =>
          import(/* webpackChunkName: "Receipts" */ "@/components/receipts/Receipts.vue")
      },
      {
        meta: {
          title: "Receipts",
          isHiddenFromSideNav: true
        },
        path: "/billing/payments/:id/receipt/:policyNo/:policyPaymentId",
        props: true,
        component: () =>
          import(/* webpackChunkName: "Receipts" */ "@/components/receipts/Receipts.vue")
      },
      {
        meta: {
          title: "Receipts",
          isHiddenFromSideNav: true
        },
        path: "/billing/policies/:policyNo/receipt/:policyPaymentId",
        props: true,
        component: () =>
          import(/* webpackChunkName: "Receipts" */ "@/components/receipts/Receipts.vue")
      },
      {
        meta: {
          title: "Email Notification",
          isHiddenFromSideNav: true
        },
        path: ":policyNo/notifications/emailNotifications/:id/:path/:version",
        props: true,

        component: () =>
          import(
            /* webpackChunkName: "Notification" */ "@/components/notifications/NotificationTemplate.vue"
          )
      },
      {
        meta: {
          title: "Email Notification",
          isHiddenFromSideNav: true
        },
        path: ":policyNo/notifications/emailNotifications/:id/:path",
        props: true,

        component: () =>
          import(
            /* webpackChunkName: "Notification" */ "@/components/notifications/NotificationTemplate.vue"
          )
      },
      {
        path: ":policyNo/:token?",
        component: () => import("@/components/routes/PolicyDetail.vue"),
        props: true,
        name: "policyDetail",
        meta: {
          title: "Policy Viewer",
          isHiddenFromSideNav: true,
          theme() {
            const productNo = store.getters["policy/productNo"];
            const { JUMPSTART_ENABLED } = Constants;
            if (JUMPSTART_ENABLED && (productNo === 4 || productNo === 5)) {
              return "earthquake";
            }
            return productNo === 1 ? "neptune" : "commercial";
          }
        }
      },
      {
        path: ":policyNo/claims/:id",
        component: () => import("@/components/policy/claims/ClaimDetail.vue"),
        props: true,
        name: "claimDetailComplete",
        meta: {
          title: "Claim View",
          isHiddenFromSideNav: true,
          theme() {
            const productNo = store.getters["policy/productNo"];
            return productNo === 1 ? "neptune" : "commercial";
          }
        }
      }
    ]
  },

  /**
   * Login View
   */
  {
    path: "/login",
    name: "login",

    beforeEnter: null,
    component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue")
  },

  /**
   * Splash
   */
  {
    path: "/splash",
    name: "Splash",
    meta: {
      theme: "light"
    },
    component: () => import(/* webpackChunkName: "Splash" */ "../views/splash/SplashPage.vue")
  },
  {
    meta: {
      title: "Bulk Upload",
      isHiddenFromSideNav: true
    },
    path: "/bulkUpload",
    name: "bulkUpload",
    component: () => import("@/components/bulkUpload/bulkUpload.vue")
  },
  /**
   * Policy Holder
   */
  ...policyHolder,

  billing,

  /**
   * User
   */
  profile,

  /**
   * Admin
   */
  ...admin,

  /** Agent */
  ...agent,

  /**
   * 404 View
   */
  {
    path: "/404",
    name: "404",
    component: Error404
  },
  /**
   * Unauthorized View
   */
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: Unauthorized
  },

  /**
   * Page Not Found
   */
  {
    path: "/error",
    name: "Error",
    component: ErrorPageNotFound,
    props: true
  }
];

const routerConfig = new Router({
  routes: routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

routerConfig.beforeEach(checkAuth);

routerConfig.afterEach((to) => {
  document.title = "Poseidon / " + (to.meta?.title || to.name || "");

  if (window.innerWidth < 768) {
    store.commit("settings/ui/SET_PROP", {
      path: "nav.hidden",
      value: true
    });
  }

  // window.scrollTo({
  //   top:      0,
  //   behavior: 'smooth'
  // })
});

/**
 * Hierarchy version of the router
 */
const h = hierarchy({ children: routes }, (v) => {
  return v.children;
});
h.each((v) =>
  Object.assign(v, {
    collapsed: false
  })
);
export const RouterHierarchy = h;

Vue.use(Router);

export default routerConfig;
