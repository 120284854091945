// import 'core-js/stable'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { any } from "ismobilejs";

// Disabling PWA, too many issues with caching
// import './registerServiceWorker'
import { detect } from "detect-browser";
import "./plugins/element.js";
import BootstrapVue from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./utils/validation";
import { TrackJS } from "trackjs";
import { Constants } from "@/config";
import AdaPlugin from "./plugins/ada";
import * as VueGoogleMaps from "vue2-google-maps";
import VueSignaturePad from "vue-signature-pad";

Vue.use(BootstrapVue);
Vue.use(VueSignaturePad);

TrackJS.install({
  ...Constants.TrackJS
});

TrackJS.addMetadata("environment", Constants.ENV);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GMAPS_KEY,
    libraries: "places"
  },
  installComponents: true
});

Vue.use(AdaPlugin, {
  enabled: Boolean(parseInt(process.env.VUE_APP_ADA_ENABLED))
});

// ? Mount Vue
// eslint-disable-next-line no-unused-vars
const vueRoot = new Vue({
  data: () => ({
    browser: detect()
  }),
  computed: {
    mobile() {
      return any;
    },
    theme() {
      const matchedRoute = [...this.$route.matched].reverse().find((r) => r.meta?.theme);
      const theme = matchedRoute?.meta?.theme;
      if (!theme) return "light";
      if (typeof theme === "function") return theme.apply(this);

      return theme;
    }
  },
  mounted() {
    let meta = {
      agent_email: null,
      agent_phone: null,
      agent_name: null,
      agent_number: null,
      environment: Constants.ENV,
      is_policy_holder: false,
      policyholder_email: null,
      policyholder_name: null,
      policyholder_phone: null
    };

    const { user, auth } = this.$store.state;
    if (auth && auth.token) {
      meta = {
        ...meta,
        auth_token: auth.token
      };
    }

    if (user && auth.accountType === "policyholder") {
      const { company, first, last, email, phone } = user;
      meta = {
        ...meta,
        policyholder_name: first && last ? `${first} ${last}` : company,
        policyholder_email: email,
        policyholder_phone: phone
      };
    } else if (auth && auth.data && auth.data.AgentId) {
      const { AgentId, FirstName, LastName, Email, Company, Phone } = auth.data;
      meta = {
        ...meta,
        agent_no: AgentId,
        agent_name: FirstName && LastName ? `${FirstName} ${LastName}` : Company,
        agent_email: Email,
        agent_phone: Phone
      };
    }

    this.$ada.setSensitiveMetaFields(meta);
  },
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");

if (process.env.NODE_ENV === "development") {
  require("@/utils/debug");

  window.store = store;
}
