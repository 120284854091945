<template>
  <div class="max-h-96">
    <div v-if="rowData.length > 0" class="table-container">
      <table class="min-w-full divide-y divide-gray-300">
        <thead class="sticky">
          <tr>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
              Claim ID
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Status
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Date of Notice
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Date of Loss
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Adjuster Name
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Adjuster Email
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Adjuster Phone
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Extension
            </th>
          </tr>
        </thead>

        <tbody class="divide-y divide-gray-200">
          <tr v-for="(claim, index) in rowData" :key="index">
            <td
              class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900"
              data-test="claimsGrid.id"
            >
              {{ claim.claimNumber }}
            </td>
            <td
              class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
              data-test="claimsGrid.status"
            >
              {{ claim.status | titleCase }}
            </td>
            <td
              class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
              data-test="claimsGrid.noticeOfLoss"
            >
              {{ claim.noticeDate | shortDateSimple }}
            </td>
            <td
              class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
              data-test="claimsGrid.noticeOfLoss"
            >
              {{ claim.dateOfLoss | shortDateSimple }}
            </td>
            <td
              class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
              data-test="claimsGrid.adjusterName"
            >
              {{ claim.adjusterName }}
            </td>
            <td
              class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
              data-test="claimsGrid.adjusterEmail"
            >
              {{ claim.adjusterEmail }}
            </td>
            <td
              class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
              data-test="claimsGrid.adjusterPhone"
            >
              {{ claim.adjusterPhone }}
            </td>
            <td
              class="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium"
              data-test="claimsGrid.adjusterExt"
            >
              {{ claim.extension }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="flex items-center justify-center w-full">
      <div class="text-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="mx-auto h-12 w-12 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            vector-effect="non-scaling-stroke"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
          />
        </svg>
        <h3 class="mt-2 text-sm font-medium text-gray-900" data-test="notificationsHeader">
          No claims have been made for this policy
        </h3>
      </div>
    </div>
  </div>
</template>
<script>
import {
  DateFiltersMixin,
  NumberFiltersMixin,
  StringFilters
} from "../../policy-wizard/utils/mixins/formatting";
import { Constants } from "@/config/index";
import { authAxios } from "@/store/modules/auth";

export default {
  mixins: [DateFiltersMixin, NumberFiltersMixin, StringFilters],
  inheritAttrs: false,
  model: {
    prop: "rowData"
  },
  props: {
    rowData: {
      type: Array
    },
    policyNo: {
      type: String
    }
  },
  data() {
    return {
      loading: false
    };
  },

  methods: {
    routeToEdit(claim) {
      this.$router.push({
        path: `${this.$route.fullPath}/claims/${claim.id}`
      });
    },
    async deleteClaim(claim) {
      try {
        this.loading = true;
        const url = `${Constants.BASE_PSDN_URL}/api/v1/claims/${claim.id}`;
        await authAxios.delete(url);
        this.$emit("update");
      } catch (e) {
        const dataArray = Object.keys(e.response.data.errors).map((k) => {
          return e.response.data.errors[k];
        });

        this.showToast(`${dataArray[0].message}`, "error", "Error");
        throw new Error(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style>
.table-container {
  max-height: 400px;
  /* Set the desired height for the table */
  overflow-y: auto;
}

thead.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}
</style>
